import React from 'react'
import { observer } from 'mobx-react-lite';
import { Button, Typography } from '@mui/material';
import { useRouter } from 'next/dist/client/router';
import styled from 'styled-components';
import { Box } from '@mui/system';
import Link from 'next/link'

const HeaderComponent = styled.div`
   background-color: white;
   z-index: 100;
   position: relative;
   border-bottom: 2px solid grey;
   margin-bottom: 2rem;
   h1 {
      margin-left: 2rem;
      @media (max-width: 650px){
         font-size: 2rem;
      }
   }
`

const Header = () => {
   const router = useRouter()
   return (
      <HeaderComponent>
         <Typography variant="h1">Wypożyczalnia klocków</Typography>
         <Box margin="1.5rem">
            <Link href="/" passHref>
               <Button href="/">Strona główna</Button>
            </Link>
            <Link href="/wypozyczalnia" passHref>
               <Button href="/wypozyczalnia">Wypożyczalnia</Button>
            </Link>
            <Link href="https://eole.pl" passHref>
               <Button href="https://eole.pl" target="_blank">Sklep</Button>
            </Link>
            <Link href="https://app.faniklockow.pl" passHref>
               <Button href="https://app.faniklockow.pl" target="_blank">Inwestuj w klocki LEGO</Button>
            </Link>
         </Box>

      </HeaderComponent>
   )
}

export default observer(Header);