import React, { FormHTMLAttributes, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  Box,
  Button,
  Card,
  Icon,
  List,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { HOST_API, REQUIRED_FIELD } from "../src/global";
import { sendMail } from "../data/mail";
import * as yup from "yup";
import Script from "next/script";
import ReCAPTCHA from "react-google-recaptcha";
import Link from "next/link";
import { city } from "../src/city";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 500px 500px;
  gap: 2rem;
  padding: 0 3rem;
  > div {
    margin: 0 auto;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    > div {
      margin: auto;
    }
  }
`;

const FooterComponent = styled.div`
  position: relative;
  z-index: 2000;
  background-color: white;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid grey;
  button {
    margin: 1rem 0;
  }
  .links {
    button {
      margin: 0;
    }
    margin-bottom: 1rem;
  }
`;

const Footer = () => {
  const [sending, setSending] = useState(false);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  return (
    <FooterComponent id="footer">
      <Script src="https://www.google.com/recaptcha/api.js"></Script>
      {/* <Script src="https://www.google.com/recaptcha/api.js?render=6Lc7v4kdAAAAAOfXhQnPAYx8YMFZZfcqu4cQr3Qz"></Script> */}
      <Grid>
        <Box>
          <Card
            elevation={3}
            style={{
              padding: "1rem",
              width: "fit-content",
              marginBottom: "2rem",
            }}
          >
            <Typography variant="h4">Wypożyczalnia Klocków</Typography>
            <Box marginLeft={"1.5rem"}>
              <Typography variant="h6">Sołtysowska 10F</Typography>
              <Typography variant="h6">31-589 Kraków</Typography>
            </Box>
          </Card>
          <Card elevation={3} style={{ padding: "1rem", width: "fit-content" }}>
            <Typography variant="h5">Możliwe odbiory osobiste</Typography>
            <Box marginLeft={"1.5rem"}>
              <List>
                <li style={{ listStyle: "disc", marginBottom: "1rem" }}>
                  <Typography>Sołtysowska 10F</Typography>
                  <Typography>31-589 Kraków</Typography>
                </li>
                <li style={{ listStyle: "disc" }}>
                  <Typography>Karasiowa</Typography>
                  <Typography>40-748 Katowice</Typography>
                </li>
              </List>
            </Box>
          </Card>
        </Box>
        <Box>
          <Typography variant="h4">Wyślij zapytanie</Typography>
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              message: "",
            }}
            onSubmit={async (values) => {
              if (!recaptchaRef.current?.getValue())
                return alert("Potwierdź, że nie jesteś robotem!");
              recaptchaRef.current.reset();
              const params = {
                name: values.name,
                phone: values.phone,
                note: values.message,
                email: values.email,
              };

              setSending(true);
              const result = await sendMail(params);
              setSending(false);
              if ("send" in result && result.send)
                alert(
                  "Wiadomość została wysłana. Poczekaj aż nasz pracownik skontaktuję się z Tobą ;)"
                );
            }}
            validationSchema={yup.object({
              name: yup
                .string()
                .required(REQUIRED_FIELD)
                .min(3, "Imię i nazwisko powinna posiadać minimum 3 znaki"),
              email: yup
                .string()
                .required(REQUIRED_FIELD)
                .email("Nie poprawny adres"),
              message: yup
                .string()
                .required(REQUIRED_FIELD)
                .min(10, "Wiadomość powinna posiadać minimum 10 znaków"),
              phone: yup
                .string()
                .min(9, "Numer telefonu powinnien posiadać minimum 9 znaków"),
            })}
          >
            {({ values, errors, handleChange, handleSubmit, touched }) => {
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onSubmit={handleSubmit}
                >
                  <TextField
                    label="Imię i nazwisko"
                    id="name"
                    name="name"
                    variant="standard"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    label="Email"
                    id="email"
                    name="email"
                    variant="standard"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    label="Telefon"
                    id="phone"
                    name="phone"
                    variant="standard"
                    value={values.phone}
                    onChange={handleChange}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                  <TextField
                    label="Wiadomość"
                    id="message"
                    name="message"
                    variant="standard"
                    value={values.message}
                    onChange={handleChange}
                    multiline
                    rows={7}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                  />
                  <div
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf5-IkdAAAAAKGcx9d4927xYgSUYBKkmfylSH6p"
                    />
                  </div>
                  <Button
                    type="submit"
                    endIcon={sending ? <Icon>hourglass_top</Icon> : null}
                    variant="outlined"
                  >
                    Wyślij
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>

      <div
        className="links"
        style={{
          display: "flex",
          // gridTemplateColumns: "1fr 1fr",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Nasze wypożyczalnie:</Typography>
        {city.map((v, k) => (
          <Link key={v} href={`${v}/wypozyczalnia`}>
            <a style={{ display: k > 5 ? "none" : "" }}>
              <Button size="small">{v}</Button>
            </a>
          </Link>
        ))}
      </div>
      <Typography
        style={{
          fontSize: ".7rem",
          marginBottom: "1.5rem",
        }}
      >
        LEGO® to znak towarowy firmy z Grupy LEGO. Niniejsza witryna nie jest
        sponsorowana, autoryzowana ani wspierana przez Grupę LEGO
      </Typography>
    </FooterComponent>
  );
};

export default observer(Footer);
