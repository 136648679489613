import { unstable_ClassNameGenerator as ClassNameGenerator } from "@mui/material/utils";

ClassNameGenerator.configure((componentName) =>
  componentName.replace("Mui", "")
);

import "../styles/global.scss";
import type { AppProps } from "next/app";
import Footer from "../component/Footer";
import Header from "../component/Header";
import styled from "styled-components";
import { useRouter } from "next/dist/client/router";
import { useEffect } from "react";
import Brick from "../component/Brick";
import { ContextProvider } from "../component/ContextApp";
import GlobalStore from "../mobx/globalStore";

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 650px) {
    width: 95%;
  }
`;

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  console.log("🚀 ~ file: _app.tsx ~ line 20 ~ MyApp ~ router");

  const handleRouteChange = (url: string) => {
    window.gtag("config", "UA-213912255-1", {
      page_path: url,
    });
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <ContextProvider value={GlobalStore}>
        <Header />
        <Brick
          top={"60%"}
          left={"90%"}
          color={"yellow"}
          rotate={"21deg"}
          scale={"2"}
        />
        <Brick
          top={"5%"}
          left={"-3%"}
          color={"green"}
          rotate={"41deg"}
          scale={"1"}
        />
        <Brick
          top={"40%"}
          left={"30%"}
          color={"blue"}
          rotate={"11deg"}
          scale={"3"}
        />
        <Content>
          <Component {...pageProps} />
        </Content>
        <Footer />
      </ContextProvider>
    </>
  );
}

export default MyApp;
