import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

const Div = styled.div<{
  top: string;
  left: string;
  color: string;
  rotate: string;
  scale: string;
}>`
  width: 10rem;
  height: 10rem;
  background-color: ${props => props.color};
  opacity: 0.5;
  position: fixed;
  top: ${props => props.top};
  left: ${props => props.left};
  transform: ${props => `rotate(${props.rotate}) scale(${props.scale})`};
  @media screen and (max-width: 600px) {
     width: 5rem;
     height: 5rem;   
  }
`;
interface IBrick {
  top: string;
  left: string;
  color: string;
  rotate: string;
  scale: string;
}

const Brick = ({ top, left, color, rotate, scale }: IBrick) => {
  return <Div top={top} left={left} rotate={rotate} color={color} scale={scale}></Div>;
};

export default observer(Brick);
