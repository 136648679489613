export const city = [
   "Warszawa",
   "Kraków",
   "Łódź",
   "Wrocław",
   "Poznań",
   "Gdańsk",
   "Szczecin",
   "Bydgoszcz",
   "Lublin",
   "Białystok",
   "Katowice",
   "Gdynia",
   "Częstochowa",
   "Radom",
   "Toruń",
   "Sosnowiec",
   "Rzeszów",
   "Kielce",
   "Gliwice",
   "Olsztyn",
   "Zabrze",
   "Bielsko-Biała",
   "Bytom",
   "Zielona Góra",
   "Rybnik",
   "Ruda Śląska",
   "Opole",
   "Tychy",
   "Gorzów Wielkopolski",
   "Elbląg",
   "Dąbrowa Górnicza",
   "Płock",
   "Wałbrzych",
   "Włocławek",
   "Tarnów",
   "Chorzów",
   "Koszalin",
   "Kalisz",
   "Legnica",
   "Grudziądz",
   "Jaworzno",
   "Słupsk",
   "Jastrzębie-Zdrój",
   "Nowy Sącz ",
   "Jelenia Góra",
   "Siedlce",
   "Mysłowice",
   "Konin",
   "Piotrków Trybunalski",
   "Lubin",
   "Inowrocław",
   "Ostrów Wielkopolski",
   "Suwałki podlaskie ",
   "Stargard",
   "Gniezno",
   "Ostrowiec Świętokrzyski",
   "Siemianowice Śląskie",
   "Głogów",
   "Pabianice",
   "Leszno",
   "Żory",
   "Zamość",
]