import { configure, makeAutoObservable } from "mobx";

configure({
  enforceActions: "observed",
});

export interface ISet {
    id_lego: number,
    price: number,
    rent_15: number,
    rent_30: number,
    name: string,
    brand: string,
}

export class GlobalStore {
  constructor() {
    makeAutoObservable(this);
  }

  sets: ISet[] = [];

  setSets(sets: ISet[]) {
    this.sets = sets;
  }
}

export default new GlobalStore();
