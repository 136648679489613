import { HOST_API } from "../src/global"

export const sendMail = async (params: any) => {
   const result = await fetch(HOST_API + "/mail/contact", {
      headers: {
         "Content-type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(params),
      // mode: "no-cors"
   })

   return result.json()
}

export const sendBorrow = async (params: any) => {
   const result = await fetch(HOST_API + "/mail/borrow", {
      headers: {
         "Content-type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(params),
      // mode: "no-cors"
   })

   return result.json()
}